import styled from "styled-components";
import React from "react";
import { mobileSize } from "../../../util/variables";
import { Link } from "gatsby";
import { TopBanner } from "../../ui/TopBanner";
import { HeroSection } from "./landing/variation/HeroSection";
import TestimonialSection from "./landing/variation/TestimonialSection";
import { AppPitchSection } from "./landing/variation/AppPitchSection";
import CoursesSection from "./landing/variation/CoursesSection";
import { LP_VARIANT_MAPPING } from "../../../util/variantMappings";
import SpecialOfferModal from "../../Modal/SpecialOfferModal";

export const VariantComponent: React.FC<{ variant: string | boolean | null }> = ({ variant }) => {
  const {
    mainTitle,
    subTitle,
    testimonialHeader,
    testimonials,
    valuePropsHeader,
    valueProps,
    heroImgDesktop,
    heroImgMobile,
    courseHeader,
  } = LP_VARIANT_MAPPING[variant as keyof typeof LP_VARIANT_MAPPING];

  return (
    <>
      <TopBanner fixed={false}>
        <BannerText>
          🏆 Winner of Google&apos;s <b>App of the Year!</b>
          <MobileBr /> Celebrate with <Link to="/onboarding/questions">20% off.</Link>
        </BannerText>
      </TopBanner>
      <HeroSection
        mainTitle={mainTitle}
        subTitle={subTitle}
        img={heroImgDesktop}
        mobileImg={heroImgMobile}
      />
      <TestimonialSection header={testimonialHeader} testimonials={testimonials} />
      <AppPitchSection header={valuePropsHeader} valueProps={valueProps} />
      <CoursesSection coursesHeader={courseHeader} />
      <SpecialOfferModal variant />
    </>
  );
};

const BannerText = styled.p`
  margin: 0;
  line-height: 1.25;
  @media ${mobileSize} {
    font-size: 20px;
  }
`;

const MobileBr = styled.br`
  display: none;
  @media ${mobileSize} {
    display: block;
  }
`;
